import repository from '@/api/repositories/repository';

export const state = {
  journeyId: null,
  journey: null,
  storefrontAccessToken: null,
  subscriptionProductId: null,
  storeDomain: null,
  cloudName: 'dvthtxxt9',
};

export const getters = {
  journeyId: state => state.journeyId,
  journey: state => state.journey,
  storefrontAccessToken: state => state.storefrontAccessToken,
  showWatermark: state => (!state.subscriptionProductId ||  state.subscriptionProductId === 1),
  storeDomain: state => state.storeDomain,
  cloudName: state => state.cloudName,
  shouldUseCDN: state => {
    return true;
    // const _productId = state.subscriptionProductId || 1;
    //
    // return _productId > 2 && ![5, 6, 7].includes(_productId);
  }
};

export const mutations = {
  SET_JOURNEY(state, journey) {
    state.journey = journey;
  },
  SET_STOREFRONT_ACCESS_TOKEN(state, storefrontAccessToken) {
    state.storefrontAccessToken = storefrontAccessToken;
  },
  SET_STORE_DOMAIN(state, storeDomain) {
    state.storeDomain = storeDomain;
  },
  SET_SUBSCRIPTION_PRODUCT_ID(state, subscriptionProductId) {
    state.subscriptionProductId = subscriptionProductId;
  },
  SET_JOURNEY_ID(state, journeyId) {
    state.journeyId = journeyId;
  },
  SET_CLOUD_NAME(state, cloudName) {
    state.cloudName = cloudName;
  },
};

export const actions = {
  async getJourney({ commit }, journeyId) {
    const { data } = await repository.getJourney(journeyId);
    commit('SET_JOURNEY', data.journey);

    commit('SET_STOREFRONT_ACCESS_TOKEN', data.storefrontAccessToken);
    // commit('SET_STOREFRONT_ACCESS_TOKEN', '7c2a45f268d1b78769bf083d629eb0fd');

    commit('SET_STORE_DOMAIN', data.storeDomain);
    // commit('SET_STORE_DOMAIN', '5081d9-3.myshopify.com');

    commit('SET_SUBSCRIPTION_PRODUCT_ID', data.subscriptionProductId);

    return data;
  },
};

export const namespaced = true;
