<template>
  <div class="video-player-component">
    <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 5px; height: 2px; background: #818790; z-index: 50; width: 97%;"></div>
    <div style="position: absolute; left: 50%; transform: translateX(-50%); top: 5px; height: 2px; z-index: 51; width: 97%;">
      <div style="height: 2px; background: #FFFFFF; z-index: 51" :style="{ width: `${playingVideoProgress}%` }"></div>
    </div>

    <div v-if="showWatermark" class="watermark-wrapper">
      <div class="watermark">
        <a href="https://tryreeltok.com/?ref=mobile-story-viewer" target="_blank">
          <p style="color: white; font-size: 13px; line-height: 2">Created with </p>
        </a>
      </div>
      <div class="watermark-logo">
        <a href="https://tryreeltok.com/" target="_blank">
          <img :src="logoImageSrc" alt="ReelTok logo" style="width: 52px; height: 12px">
        </a>
      </div>
    </div>

    <div class="control-buttons-wrapper">
      <button v-if="closeButton" class="close-button" @click="onCloseClick" @touchstart="(e) => { e.preventDefault(); onCloseClick(); }">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
      </button>

      <button class="play-button" @click="onPlayToggleClick" @touchstart="(e) => { e.preventDefault(); onPlayToggleClick(); }">
        <svg v-if="isPlaying" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
        </svg>

        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z" />
        </svg>
      </button>

      <button class="audio-button" @click="onAudioToggleClick" @touchstart="(e) => { e.preventDefault(); onAudioToggleClick(); }">
        <svg v-if="isMuted" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 9.75 19.5 12m0 0 2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6 4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"/>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
          <path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 0 1 0 12.728M16.463 8.288a5.25 5.25 0 0 1 0 7.424M6.75 8.25l4.72-4.72a.75.75 0 0 1 1.28.53v15.88a.75.75 0 0 1-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.009 9.009 0 0 1 2.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75Z"/>
        </svg>
      </button>

      <template v-if="actionType !== 'EMPTY' && actionType !== 'BUTTON'">
        <button class="cart-button" @click="onCartClick" @touchstart="(e) => { e.preventDefault(); onCartClick(); }">
          <span class="cart-bubble" v-if="cartItemsCount > 0">{{ cartItemsCount > 9 ? '9+' : cartItemsCount }}</span>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
        </button>
      </template>

      <div class="scene-controls-group" v-if="showMobileSceneControls">
        <button class="previous-scene-button" @click="onPrevSceneClick" @touchstart="(e) => { e.preventDefault(); onPrevSceneClick(); }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;"><path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" /></svg>
        </button>

        <button class="next-scene-button" @click="onNextSceneClick" @touchstart="(e) => { e.preventDefault(); onNextSceneClick(); }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 23px; height: 23px; padding: 5px;"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" /></svg>
        </button>
      </div>
    </div>

    <video
      :ref="`videoRef-${publicId}`"
      v-if="selfHostedVideo === null && shopifyVideo === null && !isInstagramVideo"
      style="object-fit: cover; height: 100% !important; width: 100% !important;"
    ></video>
    <video
      v-else
      loop
      :playsinline="true"
      :disablepictureinpicture="true"
      :controls="false"
      :ref="`videoRef-${publicId}`"
      style="object-fit: cover; position: absolute; height: 100%; width: 100%"
    ></video>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {tryGetObjectProperty} from '@/helpers/object';

export default {
  name: 'VideoPlayer',
  props: {
    cloudName: String,
    publicId: String,
    playerConfig: Object,
    sourceConfig: Object,
    isPlaying: Boolean,
    isMuted: Boolean,
    scene: Object,
    closeButton: {
      type: Boolean,
      default: true
    },
    showWatermark: {
      type: Boolean,
      default: false
    },
    showMobileSceneControls: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      playingVideoPublicId: 'videos/playingVideoPublicId',
      cartItemsCount: 'cart/itemsCount',
      shouldUseCDN: 'journeys/shouldUseCDN',
    }),
    actionType() {
      return this.scene.action?.type;
    },
    shopifyVideo() {
      const shopifyVideo = this.scene.video?.shopify_video;

      if (shopifyVideo && shopifyVideo.sources && shopifyVideo.sources.length > 0) {
        return shopifyVideo;
      }

      return null
    },
    selfHostedVideo() {
      const _selfHostedVideo = this.scene.video?.self_hosted_video;

      if (!_selfHostedVideo) {
        return null;
      }

      return _selfHostedVideo;
    },
    selfHostedVideoSrc() {
      const _selfHostedVideo = this.scene.video?.self_hosted_video;

      if (!_selfHostedVideo) {
        return null;
      }

      const shopifySources = tryGetObjectProperty(_selfHostedVideo, 'shopify_sources');

      if (shopifySources && shopifySources.length > 0) {
        return shopifySources[0].url;
      }

      let src = tryGetObjectProperty(_selfHostedVideo, 'src');

      if (!src) {
        return null;
      }

      if (this.shouldUseCDN) {
        return src.replace('reeltok-uploads.s3.us-west-1.amazonaws.com', 'd3l6cvllw26ini.cloudfront.net');
      }

      return src;
    },
    instagramSrc() {
      const instagramSrc = this.scene.video?.instagram_src;

      if (instagramSrc && instagramSrc.length > 0) {
        return instagramSrc;
      }

      return null
    },
    isInstagramVideo() {
      return this.instagramSrc !== null;
    },
  },
  data() {
    return {
      player: null,
      logoImageSrc: 'https://d1i2yc776z09uv.cloudfront.net/logo.png',
      playingVideoProgress: 0
    }
  },
  async mounted() {
    this.initVideoPlayer();

    if (this.isPlaying) {
      await this.player.play();
    } else {
      await this.player.pause();
    }
  },
  beforeDestroy() {
    if (!this.player) {
      return;
    }

    if (this.selfHostedVideoSrc || this.shopifyVideo || this.isInstagramVideo) {
      this.disposeShopifyVideoPlayer();
      return;
    }

    this.player.dispose();
  },
  watch: {
    async isPlaying(newValue) {
      if (newValue) {
        await this.player.play();

        if (this.playingVideoPublicId !== this.publicId) {
          if (this.selfHostedVideoSrc === null && this.shopifyVideo === null && !this.isInstagramVideo) {
            this.player.currentTime(0);
          } else {
            this.player.currentTime = 0;
          }

          this.$store.dispatch('videos/setPlayingVideoPublicId', this.publicId);
        }

        return;
      }

      this.player.pause();
    },
    isMuted(newValue) {
      if (this.selfHostedVideoSrc !== null || this.shopifyVideo !== null || this.isInstagramVideo) {
        this.player.muted = newValue;
        return;
      }

      if (newValue) {
        this.player.mute();
      } else {
        this.player.unmute();
      }
    }
  },
  methods: {
    disposeShopifyVideoPlayer() {
      this.player = this.$refs[`videoRef-${this.publicId}`];

      this.player.pause();

      while (this.player.firstChild) {
        this.player.removeChild(this.player.firstChild);
      }
    },
    getSmallestSizeVideoSource() {
      if (this.isInstagramVideo) {
        return [{ url: this.instagramSrc, mimeType: 'video/mp4' }];
      }

      if (this.selfHostedVideo) {
        const shopifySources = tryGetObjectProperty(this.selfHostedVideo, 'shopify_sources', []);

        if (shopifySources && shopifySources.length > 0) {
          const smallestSizeSource = shopifySources.find(source => {
            return source.height === 720;
          });

          if (smallestSizeSource) {
            return [smallestSizeSource];
          } else {
            return [shopifySources[0]];
          }
        }

        return [{ url: this.selfHostedVideoSrc, mimeType: '' }];
      }

      const shopifyVideo = this.shopifyVideo;

      const smallestSizeSource = shopifyVideo.sources.find(source => {
        return source.height === 720;
      });

      if (smallestSizeSource) {
        return [smallestSizeSource];
      }

      return [shopifyVideo.sources[0]];
    },
    initVideoPlayer() {
      this.player = this.$refs[`videoRef-${this.publicId}`];

      while (this.player.firstChild) {
        this.player.removeChild(this.player.firstChild);
      }

      this.getSmallestSizeVideoSource().forEach(source => {
        const sourceElement = document.createElement('source');
        sourceElement.src = source.url;
        sourceElement.type = source.mimeType;
        this.player.appendChild(sourceElement);
      });

      this.player.addEventListener('timeupdate', () => {
        const currentTime = this.player.currentTime;
        const duration = this.player.duration;
        const progress = ((currentTime / duration) * 100).toFixed(4);
        this.playingVideoProgress = progress;
      });

      this.player.load();
      this.player.muted = this.isMuted;
    },
    onAudioToggleClick() {
      if (this.isMuted) {
        this.$emit('unmute');
      } else {
        this.$emit('mute');
      }
    },
    onPlayToggleClick() {
      if (this.isPlaying) {
        this.$emit('pause');
      } else {
        this.$emit('play');
      }
    },
    onCartClick() {
      this.$emit('goToCart');
    },
    onCloseClick() {
      this.$emit('close');
    },
    onPrevSceneClick() {
      this.$emit('goToPrevScene');
    },
    onNextSceneClick() {
      this.$emit('goToNextScene');
    },
  }
}
</script>
