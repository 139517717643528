export const objectToQueryParams = (obj) =>  {
  return Object.keys(obj)
    .map(key => `attributes[${encodeURIComponent(key)}]=${encodeURIComponent(obj[key])}`)
    .join('&');
};

export const tryGetObjectProperty = (obj, property, defaultValue = null, ignoreCase = false) => {
  if (!obj) {
    return defaultValue;
  }

  let key = `${property}`;

  if (ignoreCase) {
    key = key.toLowerCase();
  }

  if (!obj.hasOwnProperty(key)) {
    return defaultValue;
  }

  return obj[key];
};
