<template>
  <div class="simple-button-story">
    <div class="prev-story-button-wrapper" v-if="hasControls">
      <!-- Previous Story Button -->
      <button @click="previousStory" class="story-control-button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 16px; height: 16px">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5"/>
        </svg>
      </button>
    </div>

    <div style="display: flex">
      <div style="width: 388px; height: 690px; position: relative;">
        <VideoPlayer
            :scene="scene"
            class="video-player-desktop"
            @pause="onVideoPause"
            @play="onVideoPlay"
            @unmute="onUnmute"
            @mute="onMute"
            @goToCart="onGoToCart"
            :is-playing="isPlaying"
            :is-muted="isMuted"
            :cloudName="cloudName"
            :publicId="scene.video.public_id"
            :playerConfig="{
          loop: true,
          muted: true,
          controls: false,
          bigPlayButton: false,
          responsive: true,
          aspectRatio: '9:16',
          fluid: true,
        }"
            :sourceConfig="{}"
            :close-button="false"
        />

        <!-- Action Wrapper -->
        <div v-if="showActionButton" class="action-button-wrapper">
          <button @click="handleActionButtonClick(scene)" class="action-button">{{ scene.action.label }}</button>
        </div>
      </div>

      <div v-if="isCartOpen">
        <Cart @closeCart="onCartClose" />
      </div>
    </div>

    <!-- Next Story Button -->
    <div class="next-story-button-wrapper" v-if="hasControls">
      <button @click="nextStory" class="story-control-button">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style="width: 16px; height: 16px">
          <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import VideoPlayer from '@/components/VideoPlayer.vue';
import Cart from '@/components/Cart.vue';

export default {
  name: 'SimpleButtonStory',
  components: {Cart, VideoPlayer},
  props: {
    scene: {
      type: Object,
      required: true
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
    isMuted: {
      type: Boolean,
      default: false
    },
    hasControls: {
      type: Boolean
    },
    isCartOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      cloudName: 'journeys/cloudName'
    }),
    showActionButton() {
      return this.scene.action && this.scene.action.config && this.scene.action.config.link && this.scene.action.config.link.length > 0;
    },
  },
  methods: {
    onVideoPlay() {
      this.$emit('video-play', this.scene.id);
    },
    onVideoPause() {
      this.$emit('video-pause', this.scene.id);
    },
    onUnmute() {
      this.$emit('unmute');
    },
    onMute() {
      this.$emit('mute');
    },
    onGoToCart() {
      this.$emit('goToCart');
    },
    onCartClose() {
      this.$emit('closeCart');
    },
    onClose() {
      this.$emit('close');
    },
    nextStory() {
      this.$emit('next-story');
    },
    previousStory() {
      this.$emit('previous-story');
    },
    handleActionButtonClick(scene) {
      this.onVideoPause();
      this.onClose();
      window.location.href = scene.action.config.link;
    }
  }
}
</script>

